/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-param-reassign */
import React, { useCallback, useState, useEffect } from 'react';
import ptbr from 'date-fns/locale/pt-BR';
import DatePicker, { registerLocale } from 'react-datepicker';
import moment from 'moment';
import { FiDownload } from 'react-icons/fi';

import 'react-datepicker/dist/react-datepicker.css';

import { useHistory } from 'react-router-dom';
import { Container, Content } from './styles';
import Table from '../../../components/table';
import api from '../../../services/api';

import Consts from '../../../utils/consts';

registerLocale('ptbr', ptbr);

const Simulation: React.FC = () => {
  const [startDate, setStartDate] = useState(new Date(
    new Date().setDate(new Date().getDate() - 30),
  ));
  const [endDate, setEndDate] = useState(new Date(
    new Date().setDate(new Date().getDate() + 1),
  ));
  const [data, setData] = useState([]);

  const history = useHistory();

  const handleGetProposals = useCallback(async () => {
    const proposals = await api.post('/proposals/list',
      { initial: startDate, final: endDate },
      { headers: { Authorization: `Bearer ${localStorage.getItem('@EABApi:token')}` } });

    proposals.data.map((item: any) => {
      if (item.kind === 'valor') {
        item.kind = 'R$';
      } else {
        item.kind = 'KwH';
      }

      item.created_at = moment(item.created_at).format('D/MM/Y');

      return null;
    });

    setData(proposals.data);
  }, [startDate, endDate]);

  useEffect(() => {
    handleGetProposals();
  }, [handleGetProposals]);

  return (
    <Container>
      <h1>Listagem de Solicitação de Proposta</h1>
      <Content>
        <Table
          columns={[
            { title: 'Nome', field: 'name' },
            { title: 'Sobrenome', field: 'surname' },
            { title: 'Telefone', field: 'phone' },
            { title: 'Whatsapp', field: 'whatsapp' },
            { title: 'Email', field: 'email' },
            { title: 'Pessoa', field: 'person_type' },
            { title: 'R$ / KwH', field: 'kind' },
            { title: 'Valor mensal', field: 'monthly_value' },
            { title: 'CNPJ', field: 'document' },
            { title: 'Empresa', field: 'trading_name' },
            { title: 'Solicitado Em', field: 'created_at' },
          ]}
          data={data}
          actions={[
            {
              icon: FiDownload,
              tooltip: 'Download do Arquivo',
              onClick: (e: any, d: any) => {
                window.open(`${Consts.apiUrl}/files/${d.file_url}`, '_blank');
              },
            },
          ]}
        />
      </Content>
      <br />
      <DatePicker selected={startDate} onChange={(date: Date) => setStartDate(date)} locale="ptbr" dateFormat="dd/MM/Y" />
      {' à '}
      <DatePicker selected={endDate} onChange={(date: Date) => setEndDate(date)} locale="ptbr" dateFormat="dd/MM/Y" />
    </Container>
  );
};

export default Simulation;
