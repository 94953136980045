import React, { useCallback, useRef } from 'react';
import Swal from 'sweetalert2';
import {
  FiArrowLeft, FiMail, FiUser, FiPhone, FiSmartphone, FiDollarSign, FiPower, FiUsers,
} from 'react-icons/fi';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { Link, useHistory } from 'react-router-dom';
import getValidationErrors from '../../utils/getValidationErrors';

import api from '../../services/api';

import {
  Container, Content, Background, AnimationContainer,
} from './styles';

import logoImg from '../../assets/logo.png';

import Input from '../../components/input';
import Button from '../../components/button';
import Select from '../../components/select';

import { useToast } from '../../hooks/Toast';

interface SignUpFormData {
  name: string;
  email: string;
  password: string;
}

const SignUp: React.FC = () => {
  const formRef = useRef<FormHandles>(null);

  const { addToast } = useToast();
  const history = useHistory();

  const handleSubmit = useCallback(async (data: SignUpFormData) => {
    try {
      formRef.current?.setErrors({});
      const schema = Yup.object().shape({
        name: Yup.string().required('Nome obrigatório'),
        surname: Yup.string().required('Sobrenome obrigatório'),
        email: Yup.string().required('Email obrigatório').email('Digite um e-mail válido'),
        phone: Yup.string().required('Telefone obrigatório'),
        monthly_value: Yup.string().required('Valor mensal obrigatório'),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      const response = await api.post('/simulations', data);

      const simulation = response.data;

      const analisis = await api.get(`/simulations/process/${simulation.id}`);

      Swal.fire({
        title: `Plano ${analisis.data.plan || 'I'}`,
        html: `Com este plano você garante <span style="font-weight: bold; color:green;">${analisis.data.percentage}%</span> de desconto e economiza aproximadamente <span style="font-weight: bold; color:green;">R$ ${(analisis.data.discount * 12).toFixed(2)}</span> por ano.`,
        confirmButtonText: 'Solicitar Proposta',
        showClass: {
          popup: 'animate__animated animate__fadeInDown',
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp',
        },
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          history.push(`/needProposal/${simulation.id}`);
          // Swal.fire('Em breve um dos nossos consultores entrará em contato!', '', 'success');
        }
      });
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);

        formRef.current?.setErrors(errors);
      } else {
        addToast({
          type: 'error',
          title: 'Erro no cadastro',
          description: 'Verifique as informações e tente novamente.',
        });
      }
    }
  }, [addToast, history]);

  return (
    <Container>
      <Background />
      <Content>
        <AnimationContainer>
          <img src={logoImg} alt="Moc Delivery" width="200px" />

          <Form onSubmit={handleSubmit} autoComplete="off" ref={formRef}>
            <h1>Faça sua simulação</h1>
            <Input name="name" icon={FiUser} placeholder="Nome" />
            <Input name="surname" icon={FiUser} placeholder="Sobrenome" />
            <Input name="email" icon={FiMail} placeholder="E-mail" />
            <Input name="phone" icon={FiPhone} placeholder="Telefone" />
            <Input name="whatsapp" icon={FiSmartphone} placeholder="Whatsapp" />

            <Select title="Pessoa" id="person_type" name="person_type" icon={FiUsers} placeholder="Tipo de pessoa">
              <option value="1">Jurídica</option>
              <option value="2">Física</option>
            </Select>

            <Select title="Escolha entre R$ / kWh" name="kind" icon={FiPower} placeholder="R$ / kWh">
              <option value="valor">R$</option>
              <option value="kwh">Kw/h</option>
            </Select>

            <Input name="monthly_value" icon={FiDollarSign} placeholder="Valor mensal" />

            <Button type="submit">Simular Economia</Button>
          </Form>

          <Link to="/">
            <FiArrowLeft />
            Voltar para login
          </Link>
        </AnimationContainer>
      </Content>
    </Container>
  );
};

export default SignUp;
