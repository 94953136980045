/* eslint-disable react/no-children-prop */
import React from 'react';
import { Switch } from 'react-router-dom';

import Route from './Route';

import SignIn from '../pages/SignIn';
import SignUp from '../pages/SignUp';
import Admin from '../pages/Admin';
import Dashboard from '../pages/Admin/Dashboard';
import Plans from '../pages/Admin/Plans';
import CreatePlan from '../pages/Admin/Plans/Create';
import UpdatePlan from '../pages/Admin/Plans/Update';
import Contracts from '../pages/Admin/Contracts';
import Proposals from '../pages/Admin/Proposals';
import Simulations from '../pages/Admin/Simulations';
import Users from '../pages/Admin/Users';
import NeedProposal from '../pages/NeedProposal';

const Routes: React.FC = () => (
  <Switch>
    <Route path="/" exact component={SignIn} />
    <Route path="/signup" component={SignUp} />
    <Route path="/needProposal/:id" component={NeedProposal} />

    <Route path="/dashboard" component={Admin} isPrivate>
      <Admin>
        <Dashboard />
      </Admin>
    </Route>
    <Route path="/plans" component={Admin} isPrivate>
      <Admin>
        <Plans />
      </Admin>
    </Route>
    <Route path="/newplan" component={Admin} isPrivate>
      <Admin>
        <CreatePlan />
      </Admin>
    </Route>
    <Route path="/editPlan/:id" component={Admin} isPrivate>
      <Admin>
        <UpdatePlan />
      </Admin>
    </Route>
    <Route path="/contracts" component={Admin} isPrivate>
      <Admin>
        <Contracts />
      </Admin>
    </Route>
    <Route path="/proposals" component={Admin} isPrivate>
      <Admin>
        <Proposals />
      </Admin>
    </Route>
    <Route path="/simulations" component={Admin} isPrivate>
      <Admin>
        <Simulations />
      </Admin>
    </Route>
    <Route path="/Users" component={Admin} isPrivate>
      <Admin>
        <Users />
      </Admin>
    </Route>
  </Switch>
);

export default Routes;
