import React, { useCallback } from 'react';
import { Route as ReactRoute, RouteProps as ReactRouterProps, Redirect } from 'react-router-dom';

import { useAuth } from '../hooks/Auth';

interface RouteProps extends ReactRouterProps {
  isPrivate?: boolean;
  component: React.ComponentType;
}

const Route: React.FC<RouteProps> = ({ isPrivate = false, component: Component, ...rest }) => {
  const { user } = useAuth();

  const handleRender = useCallback(() => (isPrivate === !!user ? (<Component />) : (
    <Redirect to={{ pathname: isPrivate ? '/' : '/dashboard' }} />
  )), [isPrivate, Component, user]);

  return (
    <ReactRoute {...rest} render={handleRender} />
  );
};

export default Route;
