import React from 'react';
import {
  FiHome, FiSettings, FiUser, FiArrowLeft,
} from 'react-icons/fi';
import { useHistory, useLocation } from 'react-router-dom';

import { Navigation } from 'react-minimal-side-navigation';
import 'react-minimal-side-navigation/lib/ReactMinimalSideNavigation.css';

import { useAuth } from '../../hooks/Auth';

const SideBar: React.FC = () => {
  const history = useHistory();
  const location = useLocation();

  const auth = useAuth();

  const itensAdm = [
    {
      title: 'Dashboard',
      itemId: '/dashboard',
      elemBefore: () => <FiHome />,
    },
    {
      title: 'Administração',
      itemId: 'admin',
      elemBefore: () => <FiSettings />,
      subNav: [
        {
          title: 'Simulações',
          itemId: '/simulations',
        },
        {
          title: 'Usuários',
          itemId: '/users',
        },
        {
          title: 'Planos',
          itemId: '/plans',
        },
        {
          title: 'Propostas',
          itemId: '/proposals',
        },
        {
          title: 'Contratos',
          itemId: '/contracts',
        },
      ],
    },
    {
      title: 'Sair',
      itemId: 'exitApp',
      elemBefore: () => <FiArrowLeft />,
    },
  ];

  const itensUser = [
    {
      title: 'Area do Cliente',
      itemId: 'client',
      elemBefore: () => <FiUser />,
      subNav: [
        {
          title: 'Meus Contratos',
          itemId: '/users/contracts',
        },
      ],
    },
    {
      title: 'Sair',
      itemId: 'exitApp',
      elemBefore: () => <FiArrowLeft />,
    },
  ];

  return (
    <Navigation
  // you can use your own router's api to get pathname
      activeItemId={location.pathname}
      onSelect={({ itemId }) => {
        if (itemId === 'exitApp') {
          auth.singOut();

          history.push('/');

          return;
        }

        if (itemId !== 'admin' && itemId !== 'client') history.push(itemId);
      }}
      items={auth.user?.admin ? itensAdm : itensUser}
    />
  );
};

export default SideBar;
