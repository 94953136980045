import styled from 'styled-components';

export const Container = styled.div`
  height: 100vh;

  display: flex;
  align-items: stretch;
`;

export const Content = styled.div`
  margin-top: 65px;
  margin-left: 255px;
`;

export const Header = styled.div`
  position: fixed;
  left: 0;
  top: 0;

  background-color: #596ad4;

  border-bottom: 1px solid #ccc;

  height: 60px;
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  place-content: center;

  h2 {
    margin-left: 20px;

    color: #f4ede8;
    flex: 1;

    font-weight: normal;

    small {
      margin-left: 10px;
      color: #ccc;
      font-size: 16px;
    }
  }

  a {
    text-decoration: none;

    margin-right: 20px;

    color: #f4ede8;
  }
`;

export const SideBarLayout = styled.div`
  position: fixed;
  top: 60px;

  border-right: 1px solid #000;

  background-color: #f4ede8;

  height: 100%;
  width: 250px;

  display: flex;
  flex-direction: column;

`;
