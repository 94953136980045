/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-param-reassign */
import React, { useCallback, useRef } from 'react';
import {
  FiPaperclip, FiDollarSign, FiUsers,
} from 'react-icons/fi';
import Swal from 'sweetalert2';
import ptbr from 'date-fns/locale/pt-BR';
import { registerLocale } from 'react-datepicker';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { Link, useHistory } from 'react-router-dom';
import getValidationErrors from '../../../../utils/getValidationErrors';

import 'react-datepicker/dist/react-datepicker.css';

import { Container, Content } from './styles';
import api from '../../../../services/api';

import Input from '../../../../components/input';
import Button from '../../../../components/button';
import Select from '../../../../components/select';

import { useToast } from '../../../../hooks/Toast';

registerLocale('ptbr', ptbr);

interface SignUpFormData {
  name: string;
  email: string;
  password: string;
}

const User: React.FC = () => {
  const formRef = useRef<FormHandles>(null);

  const { addToast } = useToast();
  const history = useHistory();

  const handleSubmit = useCallback(async (data: SignUpFormData) => {
    try {
      formRef.current?.setErrors({});
      const schema = Yup.object().shape({
        about: Yup.string().required('Nome obrigatório'),
        from: Yup.number().required('É necessário informar um valor inicial'),
        to: Yup.number().required('É necessário informar um valor limite'),
        discount: Yup.number().moreThan(0, 'O desconto precisa ser maior que zero.'),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      await api.post('/plans', data);

      addToast({
        type: 'success',
        title: 'Sucesso',
        description: 'Plano cadastrado com sucesso!',
      });

      history.push('/plans');
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);

        formRef.current?.setErrors(errors);
      } else {
        addToast({
          type: 'error',
          title: 'Erro no cadastro',
          description: 'Verifique as informações e tente novamente.',
        });
      }
    }
  }, [addToast, history]);

  return (
    <Container>
      <h1>Cadastro de Planos</h1>
      <Content>
        <Form onSubmit={handleSubmit} autoComplete="off" ref={formRef} style={{ width: '450px' }}>
          <Input name="about" icon={FiPaperclip} placeholder="Nome" />
          <Input name="from" icon={FiDollarSign} placeholder="De" />
          <Input name="to" icon={FiDollarSign} placeholder="Até" />
          <Input name="discount" icon={FiDollarSign} placeholder="Desconto" />

          <Select title="Ativo" id="active" name="active" icon={FiUsers} placeholder="Ativo?">
            <option value="true">Sim</option>
            <option value="false">Não</option>
          </Select>

          <Button type="submit">Cadastrar</Button>
        </Form>
      </Content>
    </Container>
  );
};

export default User;
