import React from 'react';

import { Container, Content, Card } from './styles';

const Dashboard: React.FC = () => (
  <Container>
    <h1>Dahsboard</h1>
    <Content>
      <Card style={{ color: '#FFF', backgroundColor: 'green', borderColor: '#000' }}>
        <h3>Clientes Ativos</h3>
        <h1>6</h1>
      </Card>
      <Card style={{ color: '#000', backgroundColor: 'yellow', borderColor: '#000' }}>
        <h3>Simulações</h3>
        <h1>5</h1>
      </Card>
      <Card style={{ color: '#000', backgroundColor: '#cf2', borderColor: '#000' }}>
        <h3>Propostas</h3>
        <h1>2</h1>
      </Card>
      <Card style={{ color: '#FFF', backgroundColor: 'blue', borderColor: '#000' }}>
        <h3>Contratos</h3>
        <h1>3</h1>
      </Card>
    </Content>
  </Container>
);

export default Dashboard;
