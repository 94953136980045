/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-param-reassign */
import React, { useCallback, useState, useEffect } from 'react';
import ptbr from 'date-fns/locale/pt-BR';
import { registerLocale } from 'react-datepicker';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import Edit from '@material-ui/icons/Edit';

import 'react-datepicker/dist/react-datepicker.css';

import { Container, Content } from './styles';
import Table from '../../../components/table';
import api from '../../../services/api';
import Button from '../../../components/button';

registerLocale('ptbr', ptbr);

const User: React.FC = () => {
  const [data, setData] = useState([]);

  const history = useHistory();

  const handleGetPlans = useCallback(async () => {
    const plans = await api.get('/plans',
      { headers: { Authorization: `Bearer ${localStorage.getItem('@EABApi:token')}` } });

    plans.data.map((item: any) => {
      item.created_at = moment(item.created_at).format('D/MM/Y');

      item.active = item.active ? 'Sim' : 'Não';
      item.discount = `${item.discount}%`;

      return null;
    });

    setData(plans.data);
  }, []);

  useEffect(() => {
    handleGetPlans();
  }, [handleGetPlans]);

  return (
    <Container>
      <h1>Listagem de Planos</h1>
      <Content>
        <Table
          columns={[
            { title: 'Nome', field: 'about' },
            { title: 'De', field: 'from' },
            { title: 'Até', field: 'to' },
            { title: 'Desconto', field: 'discount' },
            { title: 'Ativo', field: 'active' },
            { title: 'Cadastrado em', field: 'created_at' },
          ]}
          data={data}
          actions={[
            {
              icon: Edit,
              tooltip: 'Alterar',
              onClick: (e: any, d: any) => {
                history.push(`/editPlan/${d.id}`);
              },
            },
          ]}
        />
        <Button onClick={() => {
          history.push('/newplan');
        }}
        >
          Novo Plano
        </Button>
      </Content>
    </Container>
  );
};

export default User;
