import React from 'react';
import MaterialTable from 'material-table';

interface IColmun {
  title: string;
  field: string;
  type?: 'string' | 'boolean' | 'numeric' | 'date' | 'datetime' | 'time' | 'currency' | undefined;
}

interface TableProps {
  columns: IColmun[],
  data: Record<string, unknown>[],
  title?: string;
  actions?: any;
}

const localization = {
  body: {
    emptyDataSourceMessage: 'Sem dados para exibir',
    deleteText: 'Tem de que deseja exlcuir esta linha?',
    addTooltip: 'Adicionar',
    deleteTooltip: 'Deletar',
    editTooltip: 'Editar',
    filterRow: {
      filterPlaceHolder: 'Filtrar',
      filterTooltip: 'Filtrar',
    },
    editRow: {
      deleteText: 'Tem certeza de que deseja excluir?',
      cancelTooltip: 'Cancelar',
      saveTooltip: 'Salvar',
    },
  },
  grouping: {
    placeholder: 'Arraste o título da coluna para agrupar as linhas',
    groupedBy: 'Agrupar por: ',
  },
  header: {
    actions: 'Ações',
  },
  pagination: {
    labelDisplayedRows: '{count} de {from}-{to}',
    labelRowsSelect: 'linhas',
    labelRowsPerPage: 'Linhas por página: ',
    firstAriaLabel: 'Primeira página',
    firstTooltip: 'Primeira página',
    previousAriaLabel: 'Página anterior',
    previousTooltip: 'Página anterior',
    nextAriaLabel: 'Próxima página',
    nextTooltip: 'Próxima página',
    lastAriaLabel: 'Última página',
    lastTooltip: 'Última página',
  },
  toolbar: {
    addRemoveColumns: 'Adicionar ou remover colunas',
    nRowsSelected: '{0} linhas(s) selecionadas',
    showColumnsTitle: 'Mostrar colunas',
    showColumnsAriaLabel: 'Mostrar colunas',
    exportTitle: 'Exportar',
    exportAriaLabel: 'Exportar',
    exportName: 'Exportar como CSV',
    searchTooltip: 'Pesquisar',
    searchPlaceholder: 'Pesquise aqui...',
  },
};

const Table: React.FC<TableProps> = ({
  columns, data, title, actions,
}) => (
  <MaterialTable
    columns={columns}
    data={data}
    title={title || ''}
    localization={localization}
    actions={actions}
  />
);

export default Table;
