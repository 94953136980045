import React from 'react';
import { FiUser } from 'react-icons/fi';

import {
  Container, Header, SideBarLayout, Content,
} from './styles';

import SideBar from '../../components/SideBar';
import logoImg from '../../assets/logo.png';

const Application: React.FC = ({ children }) => (
  <Container>
    <Header>
      <h2>
        <img src={logoImg} alt="EAB" width="130px" />
      </h2>
      <a href="nothing">
        <FiUser />
        {' '}
        Deivid Alvacy Reis de Castro
      </a>
    </Header>
    <SideBarLayout>
      <SideBar />
    </SideBarLayout>
    <Content>
      { children }
    </Content>
  </Container>
);

export default Application;
