import styled from 'styled-components';

export const Container = styled.div`
  padding: 15px;
  width: 100%;
`;

export const Content = styled.div`
  margin-top: 20px;
  width: 100%;

  Table {
    width: 80vw;
  }
`;
