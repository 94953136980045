/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-param-reassign */
import React, { useCallback, useState, useEffect } from 'react';
import ptbr from 'date-fns/locale/pt-BR';
import { registerLocale } from 'react-datepicker';
import moment from 'moment';

import 'react-datepicker/dist/react-datepicker.css';

import { Container, Content } from './styles';
import Table from '../../../components/table';
import api from '../../../services/api';

registerLocale('ptbr', ptbr);

const User: React.FC = () => {
  const [data, setData] = useState([]);

  const handleGetSimulations = useCallback(async () => {
    const users = await api.get('/admin',
      { headers: { Authorization: `Bearer ${localStorage.getItem('@EABApi:token')}` } });

    users.data.map((item: any) => {
      item.created_at = moment(item.created_at).format('D/MM/Y');

      item.administrator = item.admin ? 'Sim' : 'Não';

      return null;
    });

    setData(users.data);
  }, []);

  useEffect(() => {
    handleGetSimulations();
  }, [handleGetSimulations]);

  return (
    <Container>
      <h1>Listagem de Usuários</h1>
      <Content>
        <Table
          columns={[
            { title: 'Nome', field: 'name' },
            { title: 'Email', field: 'email' },
            { title: 'Cadastrado em', field: 'created_at' },
            { title: 'Administrador', field: 'administrator' },
          ]}
          data={data}

        />
      </Content>
    </Container>
  );
};

export default User;
