import styled from 'styled-components';

export const Container = styled.div`
  padding: 15px;
  width: 100%;
`;

export const Content = styled.div`
  display: flex;
`;

export const Card = styled.div`
  width: 350px;
  height: 220px;

  background-color: #cccccc;
  border: 1px solid #fff;
  border-radius: 50px;

  color: #212121;
  margin: 15px;

  display: flex;
  flex-direction: column;

  h3 {
    margin-left: 30px;
    margin-top: 8px;
    font-size: 20px;
  }

  h1 {
    text-align: center;
    place-content: center;
    margin-top: 50px;

    font-size: 50px;

    flex: 1;
  }
`;
