/* eslint-disable camelcase */
import React, {
  ChangeEvent, useCallback, useEffect, useRef, useState,
} from 'react';
import Swal from 'sweetalert2';
import {
  FiArrowLeft, FiMail, FiUser, FiPhone, FiSmartphone,
  FiDollarSign, FiPower, FiUsers, FiList, FiHome, FiFile,
} from 'react-icons/fi';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { Link, useHistory, useParams } from 'react-router-dom';
import getValidationErrors from '../../utils/getValidationErrors';

import api from '../../services/api';

import {
  Container, Content, Background, AnimationContainer,
} from './styles';

import logoImg from '../../assets/logo.png';

import Input from '../../components/input';
import Button from '../../components/button';
import Select from '../../components/select';

import { useToast } from '../../hooks/Toast';

interface SignUpFormData {
  name: string;
  surname: string;
  email: string;
  password: string;
  monthly_value: string;
  whatsapp: string;
  person_type: string;
  kind: string;
  document: string;
  phone: string;
  trading_name: string;
  documentfile: any;
}

const NeedProposal: React.FC = () => {
  const formRef = useRef<FormHandles>(null);

  const [formData, setFormData] = useState<FormData>(new FormData());

  const { id } = useParams() as { id: string; };

  const { addToast } = useToast();

  const handleFileChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target.files) {
        formData.set(
          'documentfile',
          e.target.files[0],
          e.target.files[0].name,
        );
      }
    },
    [formData],
  );

  const handleSubmit = useCallback(async (data: SignUpFormData) => {
    try {
      formRef.current?.setErrors({});
      const schema = Yup.object().shape({
        name: Yup.string().required('Nome obrigatório'),
        surname: Yup.string().required('Sobrenome obrigatório'),
        email: Yup.string().required('Email obrigatório').email('Digite um e-mail válido'),
        phone: Yup.string().required('Telefone obrigatório'),
        monthly_value: Yup.string().required('Valor mensal obrigatório'),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      formData.set('name', data.name);
      formData.set('surname', data.surname);
      formData.set('email', data.email);
      formData.set('phone', data.phone);
      formData.set('monthly_value', data.monthly_value);
      formData.set('whatsapp', data.whatsapp);
      formData.set('person_type', data.person_type);
      formData.set('kind', data.kind);
      formData.set('document', data.document);
      formData.set('trading_name', data.trading_name);

      await api.post('/proposals', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      Swal.fire('Proposata solicitada com sucesso! Em breve um dos nossos consultores entrará em contato.', '', 'success');
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);

        formRef.current?.setErrors(errors);
      } else {
        addToast({
          type: 'error',
          title: 'Erro no cadastro',
          description: 'Verifique as informações e tente novamente.',
        });
      }
    }
  }, [addToast, formData]);

  const handleLoadProposal = useCallback(async (): Promise<void> => {
    const analisis = await api.get(`/simulations/process/${id}`);

    const { simulation } = analisis.data;

    formRef.current?.setData({
      name: simulation.name,
      surname: simulation.surname,
      email: simulation.email,
      phone: simulation.phone,
      whatsapp: simulation.whatsapp,
      person_type: simulation.person_type,
      kind: simulation.kind,
      monthly_value: simulation.monthly_value,
    });
  }, [id]);

  useEffect(() => {
    handleLoadProposal();
  },
  [handleLoadProposal]);

  return (
    <Container>
      <Background />
      <Content>
        <AnimationContainer>
          <img src={logoImg} alt="Moc Delivery" width="200px" />

          <Form onSubmit={handleSubmit} autoComplete="off" ref={formRef}>
            <h1>Solicite sua Proposta</h1>
            <Input name="name" icon={FiUser} placeholder="Nome" />
            <Input name="surname" icon={FiUser} placeholder="Sobrenome" />
            <Input name="email" icon={FiMail} placeholder="E-mail" />
            <Input name="phone" icon={FiPhone} placeholder="Telefone" />
            <Input name="whatsapp" icon={FiSmartphone} placeholder="Whatsapp" />

            <Select title="Pessoa" id="person_type" name="person_type" icon={FiUsers} placeholder="Tipo de pessoa">
              <option value="1">Jurídica</option>
              <option value="2">Física</option>
            </Select>

            <Select title="Escolha entre R$ / kWh" name="kind" icon={FiPower} placeholder="R$ / kWh">
              <option value="valor">R$</option>
              <option value="kwh">Kw/h</option>
            </Select>

            <Input name="monthly_value" icon={FiDollarSign} placeholder="Valor mensal" />

            <Input name="document" icon={FiList} placeholder="CNPJ" />
            <Input name="trading_name" icon={FiHome} placeholder="Nome da Empresa" />
            <Input type="file" name="documentfile" onChange={handleFileChange} icon={FiFile} placeholder="Conta de Energia" />

            <Button type="submit">Solicitar Proposta</Button>
          </Form>

          <Link to="/">
            <FiArrowLeft />
            Voltar para login
          </Link>
        </AnimationContainer>
      </Content>
    </Container>
  );
};

export default NeedProposal;
